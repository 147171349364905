<template>
  <div v-if="isAdmin || checkAccess('staffs')">
    <report-list-page
        page-title="Staff Report"
        page-icon="mdi-account"
        :descending="true"
        sort="totalLink"
        :headers="headers"
        state-end-point="staff.staffs"
        :actions="actions"
        :show-component="showComponent"
        :show-grand-total="false"
        item-key="id"
    />
  </div>
</template>

<script>
import ReportListPage from "../../components/ReportListPage";
import IsAuthenticated from "../../middleware/IsAuthenticated";
export default {
  name: "StaffReport",
  mixins: [IsAuthenticated],
  components: {ReportListPage},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Total Order',
          value: 'totalOrder',
          align: 'center'
        },
        {
          text: 'Total Link',
          value: 'totalLink',
          align: 'center'
        },
      ],
      actions: {
        load: 'loadStaffs',
        downloadPDF: 'downloadStaffAsPDF'
      },
      showComponent: {
        isUser: false,
        isStatus: false,
        isMethod: false,
        isEmail: false,
        isSite: false,
        isPDF: false,
        isDate: true,
        isStaffPDF: true
      }
    }
  }
}
</script>

<style scoped>

</style>